import { CMSView } from '@camberi/firecms'
import React from 'react'
import MigrationView from 'src/components/views/migration/MigrationView'

const migrationCMSView: CMSView = {
  path: 'migrations',
  name: 'Migrations',
  group: 'Admin',
  view: <MigrationView />
}

export default migrationCMSView
