import { CMSView } from '@camberi/firecms'
import React from 'react'
import AccountView from 'src/components/views/account/AccountView'

const migrationCMSView: CMSView = {
  path: 'accounts',
  name: 'Comptes',
  description: 'Gestion des comptes utilisateurs.',
  group: 'Données',
  view: <AccountView />
}

export default migrationCMSView
