import { Image } from '@businessman/types'
import { buildCollection, EntityCollection, EntityOnSaveProps } from '@camberi/firecms'
import { getAnalytics, logEvent } from 'firebase/analytics'
import imageSchema from 'src/collections/image/imageSchema'
const imageCollection: EntityCollection<Image> = buildCollection({
  name: 'Images',
  path: 'images',
  schema: imageSchema,
  group: 'Données',
  permissions: ({ user, authController }) => ({
    edit: true,
    create: true,
    delete: true
  }),
  excludedProperties: ['createdAt', 'updatedAt'],
  defaultSize: 'm',
  callbacks: {
    onSaveSuccess(props: EntityOnSaveProps<Image>) {
      const analytics = getAnalytics()
      // Try logging a few action
      logEvent(analytics, 'event_admin_test', props)
      logEvent(analytics, 'notification_received')
      logEvent(analytics, 'data_updated', props)
      logEvent(analytics, 'select_content', props)
      logEvent(analytics, 'exception', props)
    }
  }
})

export default imageCollection
