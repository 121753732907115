import { buildCollection } from '@camberi/firecms'
import moduleSchema, { LightModule } from 'src/collections/module/moduleSchema'

const userCollection = buildCollection<LightModule>({
  name: 'Modules',
  path: 'modules',
  schema: moduleSchema,
  group: 'Données',
  excludedProperties: ['createdAt', 'updatedAt'],
  defaultSize: 'xs'
})

export default userCollection
