import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { Box, Button, Container, Grid, Link, Typography } from '@mui/material'
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import Copyright from 'src/components/molecules/Copyright'
import getFunctionCallable from 'src/utils/getFunctionCallable'

export default function AccountView() {
  type accountType = {
    displayName?: string
    email: string
    phoneNumber?: string
    customClaims?: object
  }
  const [accountsData, setAccountsData] = useState<accountType[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)
  const [isSelectionChanged, setIsSelectionChange] = useState<boolean>(false)
  useEffect(() => {
    const getAccounts = async () => {
      const accountPersistence = getFunctionCallable('accountPersistence')
      try {
        // const accounts: {data: UserClaims[]}
        const accounts: any = await accountPersistence({ action: 'findAll' })

        // Add custom data

        accounts.data.map(async (value: any, index: number) => {
          // todo : set type
          let docRef: any
          let docSnap: any
          let enterpriseName = ''
          if (accounts.data[index].customClaims?.enterpriseId) {
            docRef = doc(
              getFirestore(),
              'enterprises',
              accounts.data[index].customClaims?.enterpriseId
            )
            docSnap = await getDoc(docRef)
            enterpriseName = docSnap ? (docSnap.data() ? docSnap.data().name : '') : ''
          }

          accounts.data[index].enterpriseId = accounts.data[index].customClaims?.enterpriseId ?? ''
          accounts.data[index].enterpriseName = enterpriseName
          accounts.data[index].userId = accounts.data[index].customClaims?.userId ?? ''
          accounts.data[index].actions = ''
        })
        setAccountsData(accounts.data)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.error(e)
      }
    }
    getAccounts().catch((e: any) => console.log(e))
  }, [isSelectionChanged])

  return (
    <Container maxWidth="xl" className={'classes.container'} style={{ paddingTop: '80px' }}>
      <Grid container spacing={3}>
        <Box pb={2}>
          <Typography variant="h4" color="inherit">
            Liste des comptes
          </Typography>
        </Box>
        <div style={{ height: 900, width: '100%' }}>
          <DataGrid
            getRowId={(row) => row.uid}
            components={{
              Toolbar: GridToolbar
            }}
            rows={accountsData ?? []}
            columns={columns}
            pageSize={100}
            rowHeight={150}
            checkboxSelection={false}
            onCellClick={({ selectionModel }: any) => {
              setIsSelectionChange(!isSelectionChanged)
            }}
            loading={isLoading}
          />
        </div>
      </Grid>
      <Box pt={4}>
        <Copyright />
      </Box>
    </Container>
  )
}

const columns: GridColDef[] = [
  {
    field: 'displayName',
    headerName: 'Nom',
    align: 'left',
    headerAlign: 'left',
    width: 200
  },
  {
    field: 'email',
    headerName: 'Email',
    align: 'left',
    headerAlign: 'left',
    width: 300
  },
  {
    field: 'phoneNumber',
    headerName: 'Numéro de téléphone',
    align: 'left',
    headerAlign: 'left',
    width: 300
  },
  {
    field: 'enterpriseName',
    headerName: "Nom de l'entreprise",
    align: 'left',
    headerAlign: 'left',
    width: 300
  },
  {
    field: 'customClaims',
    headerName: 'Claims',
    align: 'left',
    headerAlign: 'left',
    width: 400,
    renderCell: (params) => {
      return <pre style={{ lineHeight: 1 }}>{JSON.stringify(params.row.customClaims, null, 2)}</pre>
    }
  },
  {
    field: 'disabled',
    headerName: 'Actif',
    align: 'center',
    headerAlign: 'left',
    width: 150,
    renderCell: (params) => {
      return params.row.disabled ? <VisibilityOffIcon /> : <VisibilityIcon />
    }
  },
  {
    field: 'actions',
    headerName: 'Actions',
    align: 'left',
    headerAlign: 'left',
    width: 200,
    renderCell: (params) => {
      let account = undefined
      const deleteAccount = async ({ accountId }: { accountId: string }) => {
        const accountPersistence = getFunctionCallable('accountPersistence')
        try {
          return (await accountPersistence({ action: 'delete', accountId })) as any
        } catch (e) {
          console.error(e)
        }
      }
      return (
        <>
          <Button
            variant="contained"
            size="small"
            onClick={async () => {
              if (window.confirm(`Ètes vous sûr de supprimer le compte ${params.row.email}`)) {
                account = await deleteAccount({ accountId: params.row.uid })
                if (account) alert(`Vous avez supprimé le compte ${account?.data?.email}`)
              }
            }}
            style={{ margin: 10 }}
            startIcon={<DeleteIcon style={{ marginRight: -8 }} />}></Button>
          {params.row.enterpriseId && params.row.userId && (
            <Link
              target="_blank"
              href={`c/enterprises/${params.row.enterpriseId}/users/${params.row.userId}`}
              underline="none">
              <Button
                variant="contained"
                size="small"
                style={{ margin: 10 }}
                startIcon={<EditIcon style={{ marginRight: -8 }} />}></Button>
            </Link>
          )}
        </>
      )
    }
  }
]

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     container: {
//       marginTop: theme.spacing(1),
//       marginBottom: theme.spacing(1)
//     }
//   })
// )
