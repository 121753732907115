import { config } from '@businessman/config/dist/config'
import { getApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'

export default function getFunctionCallable(functionName: string) {
  const functions = getFunctions(
    getApp(),
    process.env.REACT_APP_CONTEXT === 'local' ? undefined : config.get('region')
  )
  return httpsCallable(functions, functionName, { timeout: 540000 })
}
