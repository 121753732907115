import {
  Authenticator,
  FirebaseCMSApp,
  Navigation,
  NavigationBuilder,
  NavigationBuilderProps
} from '@camberi/firecms'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { getApp } from 'firebase/app'
import { EmailAuthProvider, User as FirebaseUser } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { fetchAndActivate, getRemoteConfig, getString } from 'firebase/remote-config'
import i18next from 'i18next'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import enterpriseCollection from 'src/collections/enterprise'
import imageCollection from 'src/collections/image'
import labelCollection from 'src/collections/label'
import moduleCollection from 'src/collections/module'
import { accountCMSView, migrationCMSView } from 'src/components/views/index'
import { textSearchController } from 'src/utils/algolia'
import { firebaseConfig } from './firebaseConfig'
import './i18next-init'
import logo from './images/businessman_logo.png'
import * as serviceWorker from './serviceWorker'

const myAuthenticator: Authenticator<FirebaseUser> = async ({ user, authController }) => {
  const email = user?.email ?? ''
  console.log('Allowing access to', email)

  const remoteConfig = getRemoteConfig()

  try {
    await fetchAndActivate(remoteConfig)
  } catch (error: any) {
    console.error('Firebase Remote Config failed to initialize', error)
    return false
  }
  const adminEmails: string[] = JSON.parse(getString(remoteConfig, 'adminEmails'))

  console.log(adminEmails)
  console.log(email)
  console.log(adminEmails.includes(email))

  // Analytics
  const analytics = getAnalytics()
  logEvent(analytics, 'login', {
    method: 'login',
    from: 'admin.businessman.ch'
  })

  // This is an example of retrieving async data related to the user
  // and storing it in the user extra field.
  const sampleUserData = await Promise.resolve({
    roles: ['admin']
  })

  authController.setExtra(sampleUserData)
  return adminEmails.includes(email)
}

const onFirebaseInit = (config: Object) => {
  if (process.env.REACT_APP_CONTEXT === 'local') {
    console.log('Data from local emulator!')

    // Firestore
    const db = getFirestore()
    connectFirestoreEmulator(db, 'localhost', 3600)

    // Cloud Function
    const functions = getFunctions(getApp())
    connectFunctionsEmulator(functions, 'localhost', 5001)

    // Auth
    // firebase.auth().useEmulator('http://localhost:9099');
  }
}

const navigation: NavigationBuilder = ({ user }: NavigationBuilderProps) => {
  const navigation: Navigation = {
    collections: [enterpriseCollection, imageCollection, labelCollection, moduleCollection],
    views: [
      // notificationCMSView,
      // statisticCMSView,
      // // backupCMSView,
      // searchIndexesCMSView,
      // fileCMSView,
      // integrityCMSView,
      accountCMSView,
      migrationCMSView
      // taskCMSView
    ]
  }

  return navigation
}

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <Suspense fallback="loading">
      <FirebaseCMSApp
        name={`Businessman Admin ${
          process.env.REACT_APP_CONTEXT === 'local'
            ? `(${process.env.REACT_APP_CONTEXT})`
            : process.env.REACT_APP_APPLICATION_CONTEXT_NAME
        }`}
        authentication={myAuthenticator}
        signInOptions={[EmailAuthProvider.PROVIDER_ID]}
        textSearchController={textSearchController}
        allowSkipLogin={false}
        logo={logo}
        navigation={navigation}
        onFirebaseInit={onFirebaseInit}
        // In the production environment, the configuration is fetched from the environment automatically
        firebaseConfig={process.env.NODE_ENV !== 'production' ? firebaseConfig : undefined}
        toolbarExtraWidget={<></>}
      />
    </Suspense>
  </I18nextProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister()
