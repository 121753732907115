import { PastSubscription } from '@businessman/types'
import { buildSchema } from '@camberi/firecms'

export interface LightPastSubscriptions extends Omit<PastSubscription, 'status'> {}

const pastSubscriptionsSchema = buildSchema<LightPastSubscriptions>({
  name: 'Historique des abonnements',
  properties: {
    totalPrice: {
      title: 'Prix',
      dataType: 'number'
    },
    discount: {
      title: 'Rabais',
      dataType: 'number'
    },
    discountPrice: {
      title: 'Prix total après rabais',
      dataType: 'number'
    },
    modules: {
      title: 'Modules',
      dataType: 'array',
      of: {
        dataType: 'map',
        properties: {
          moduleName: {
            title: 'Nom',
            dataType: 'string'
          },
          modulePrice: {
            title: 'Prix',
            dataType: 'number'
          },
          module: {
            title: 'Module',
            dataType: 'reference',
            path: 'modules'
          }
        }
      }
    },
    startDate: {
      title: 'Date de début',
      // @ts-ignore
      dataType: 'timestamp'
    },
    endDate: {
      title: 'Date de fin',
      // @ts-ignore
      dataType: 'timestamp'
    },
    createdAt: {
      title: 'Crée le',
      // @ts-ignore
      dataType: 'timestamp'
    },
    updatedAt: {
      title: 'Modifié le',
      // @ts-ignore
      dataType: 'timestamp'
    }
  },
  defaultValues: {
    visible: true
  }
})

export default pastSubscriptionsSchema
