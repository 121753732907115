import { Module } from '@businessman/types'
import { buildSchema } from '@camberi/firecms'

export interface LightModule extends Omit<Module, 'module' | 'modulePrice'> {}

const moduleSchema = buildSchema<LightModule>({
  name: 'Modules',
  properties: {
    name: {
      title: 'nom',
      dataType: 'string',
      validation: {
        required: true
      }
    },
    code: {
      title: 'Code',
      dataType: 'string',
      validation: {
        required: true
      }
    },
    pricePerMonth: {
      title: 'Tarif mensuel',
      dataType: 'number'
    },
    pricePerYear: {
      title: 'Tarif annuel',
      dataType: 'number'
    },
    createdAt: {
      title: 'Crée le',
      // @ts-ignore
      dataType: 'timestamp'
    },
    updatedAt: {
      title: 'Modifié le',
      // @ts-ignore
      dataType: 'timestamp'
    }
  },
  defaultValues: {
    visible: true
  }
})

export default moduleSchema
