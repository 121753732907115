import { Enterprise } from '@businessman/types'
import { buildSchema } from '@camberi/firecms'

export interface LightEnterprise
  extends Omit<
    Enterprise,
    'users' | 'categories' | 'pastSubscriptions' | 'pastPresences' | 'subsidiaries'
  > {}

const enterpriseSchema = buildSchema<LightEnterprise>({
  name: 'Entreprise',
  customId: true,
  properties: {
    active: {
      title: 'Actif',
      dataType: 'boolean'
    },
    name: {
      title: 'Nom',
      dataType: 'string',
      validation: {
        required: true
      }
    },
    createdAt: {
      title: 'Crée le',
      // @ts-ignore
      dataType: 'timestamp'
    },
    updatedAt: {
      title: 'Modifié le',
      // @ts-ignore
      dataType: 'timestamp'
    },
    phoneNumbers: {
      title: 'Numéros de téléphone',
      dataType: 'array',
      of: {
        dataType: 'map',
        properties: {
          comment: {
            title: 'Commentaire',
            dataType: 'string'
          },
          phoneNumber: {
            title: 'Numéros de téléphone',
            dataType: 'string'
          }
        }
      }
    },
    modules: {
      title: 'Modules',
      dataType: 'map',
      properties: {
        currentSubscription: {
          dataType: 'map',
          properties: {
            startDate: {
              title: 'Date de début',
              dataType: 'timestamp'
            },
            endDate: {
              title: 'Date de fin',
              dataType: 'timestamp'
            },
            totalPrice: {
              title: 'Prix total',
              dataType: 'number'
            },
            discount: {
              title: 'Rabais',
              dataType: 'number'
            },
            discountPrice: {
              title: 'Prix total après rabais',
              dataType: 'number'
            }
          }
        },
        currentModules: {
          title: 'Modules courants',
          dataType: 'array',
          of: {
            dataType: 'map',
            properties: {
              moduleName: {
                title: 'Nom',
                dataType: 'string'
              },
              modulePrice: {
                title: 'Prix',
                dataType: 'number'
              },
              module: {
                title: 'Module',
                dataType: 'reference',
                path: 'modules'
              }
            }
          }
        }
      }
    }
  },
  defaultValues: {}
})

export default enterpriseSchema
