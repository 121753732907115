import { buildCollection } from '@camberi/firecms'
import labelSchema from 'src/collections/label/labelSchema'

const labelCollection = buildCollection({
  name: 'Libellés',
  path: 'labels',
  schema: labelSchema,
  group: 'Données',
  defaultSize: 'm',
  permissions: ({ user, authController }) => ({
    edit: true,
    create: true,
    delete: authController.extra.roles.includes('admin')
  })
})

export default labelCollection
