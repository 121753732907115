import { FirestoreTextSearchController, performAlgoliaTextSearch } from '@camberi/firecms'
import algoliasearch, { SearchClient } from 'algoliasearch'

if (!process.env.REACT_APP_ALGOLIA_APP_ID || !process.env.REACT_APP_ALGOLIA_SEARCH_KEY) {
  console.error(
    'REACT_APP_ALGOLIA_APP_ID or REACT_APP_ALGOLIA_SEARCH_KEY env variables not specified'
  )
  console.error('Text search not enabled')
}

let client: SearchClient | undefined = undefined
client = algoliasearch(
  String(process.env.REACT_APP_ALGOLIA_APP_ID),
  String(process.env.REACT_APP_ALGOLIA_SEARCH_KEY)
)
const enterprisesIndex = client.initIndex('enterprises')
const usersIndex = client.initIndex('users')

export const textSearchController: FirestoreTextSearchController = ({ path, searchString }) => {
  if (path === 'enterprises') return performAlgoliaTextSearch(enterprisesIndex, searchString)
  if (path === 'users') return performAlgoliaTextSearch(usersIndex, searchString)
  return undefined
}
