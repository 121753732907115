import { Status } from '@businessman/types'
import { buildSchema } from '@camberi/firecms'

export interface LightPastPresences extends Omit<Status, ''> {}

const pastPresencesSchema = buildSchema<LightPastPresences>({
  name: 'Historique des présences',
  properties: {
    userDisplayName: {
      title: 'Nom complet',
      dataType: 'string'
    },
    value: {
      title: 'Statut',
      dataType: 'string'
    },
    message: {
      title: 'Message',
      dataType: 'string'
    },
    createdAt: {
      title: 'Créé le',
      // @ts-ignore
      dataType: 'timestamp'
    }
  },
  defaultValues: {
    visible: true
  }
})

export default pastPresencesSchema
