import { AdditionalColumnDelegate } from '@camberi/firecms'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Button } from '@mui/material'
import React from 'react'
import getFunctionCallable from 'src/utils/getFunctionCallable'

type Result = {
  type: 'ok' | 'fail'
}

const enterpriseAdditionalColumn: AdditionalColumnDelegate = {
  id: 'reset_password',
  title: 'Reset all accounts',
  builder: (context) => (
    <Button
      variant="contained"
      onClick={async (e) => {
        e.stopPropagation()
        try {
          const accountPersistence = getFunctionCallable('accountPersistence')
          const result = (await accountPersistence({
            action: 'resetOne',
            userPath: context.entity.path
          })) as unknown as Result
          if (result.type === 'ok') {
            alert(
              `Les comptes ont été réinitialisé pour l'entreprise ${context.entity.values.name}`
            )
          } else {
            alert(
              `Un problème est survenu en réinitialisant les comptes pour l'entreprise ${context.entity.values.name}`
            )
          }
        } catch (e: any) {
          console.log(e)
        }
      }}>
      <AccountCircleIcon />
      &nbsp; Réinitialiser
    </Button>
  )
}

export default enterpriseAdditionalColumn
