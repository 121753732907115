import { config } from '@businessman/config/dist/config'

export default function getFunctionUrl(functionName: string) {
  return process.env.REACT_APP_CONTEXT === 'local'
    ? `http://localhost:5000/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/${config.get(
        'region'
      )}/${functionName}`
    : `https://${config.get('region')}-${
        process.env.REACT_APP_FIREBASE_PROJECT_ID
      }.cloudfunctions.net/${functionName}`
}
