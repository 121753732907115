import { Link, Typography } from '@mui/material'
import React from 'react'

export default function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="https://businessman.ch">
        Businessman
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}
