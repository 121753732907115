import { User } from '@businessman/types'
import { buildSchema, EnumValues } from '@camberi/firecms'

const roles: EnumValues = {
  manager: 'Manager',
  coworker: 'Collaborateur'
}

export interface LightUser extends Omit<User, 'id' | 'birthDate'> {
  password: string
}

const userSchema = buildSchema<LightUser>({
  name: 'Utilisateur',
  customId: true,
  properties: {
    active: {
      dataType: 'boolean',
      title: 'Actif'
    },
    firstName: {
      title: 'Prénom',
      dataType: 'string',
      validation: {
        required: true
      }
    },
    lastName: {
      title: 'Nom',
      dataType: 'string',
      validation: {
        required: true
      }
    },
    email: {
      title: 'Email',
      dataType: 'string'
    },
    phoneNumber: {
      title: 'Téléphone',
      dataType: 'string',
      validation: {
        required: true,
        requiredMessage: 'Le numéro de téléphone  est obligatoire'
      }
    },
    password: {
      title: 'Mot de passe',
      dataType: 'string',
      description:
        'Laissez le champ vide pour ne pas changer le mot de passe. 6 caractères minimum.',
      validation: {
        min: 6
      }
    },
    latestStatus: {
      title: 'Dernier status',
      dataType: 'map',
      properties: {
        value: {
          title: 'Valeur',
          dataType: 'string'
        },
        updatedAt: {
          title: 'Modifié',
          dataType: 'timestamp'
        }
      }
    },
    roles: {
      title: 'Roles',
      dataType: 'array',
      validation: {
        min: 1,
        required: true,
        requiredMessage: 'Veuillez sélectionner un rôle dans la liste'
      },
      of: {
        dataType: 'string',
        config: {
          enumValues: roles
        }
      }
    },
    preferences: {
      title: 'Préferences',
      dataType: 'map',
      properties: {
        showInPresenceList: {
          title: 'Visible dans la liste des présences',
          dataType: 'boolean'
        },
        showNotificationPreference: {
          title: "Premission d'activer les notifications",
          dataType: 'boolean'
        },
        isNotificationEnable: {
          title: 'Notification actif',
          dataType: 'boolean'
        }
      }
    },
    createdAt: {
      title: 'Crée le',
      // @ts-ignore
      dataType: 'timestamp'
    },
    updatedAt: {
      title: 'Modifié le',
      // @ts-ignore
      dataType: 'timestamp'
    }
  },
  defaultValues: {
    visible: true
  }
})

export default userSchema
