import { config } from '@businessman/config'
import { User } from '@businessman/types'
import { buildCollection, EntityCollection, EntityOnSaveProps } from '@camberi/firecms'
import enterpriseAdditionalColumn from 'src/collections/enterprise/enterpriseAdditionalColumn'
import enterpriseSchema, { LightEnterprise } from 'src/collections/enterprise/enterpriseSchema'
import pastPresencesSchema from 'src/collections/pastPresences/pastPresencesSchema'
import pastSubscriptionsSchema from 'src/collections/pastSubscriptions/pastSubscriptionsSchema'
import userSchema from 'src/collections/user/userSchema'
import getFunctionCallable from 'src/utils/getFunctionCallable'
// import { enterprisesSearchDelegate } from 'src/utils/algolia'

const enterpriseCollection: EntityCollection<LightEnterprise> = buildCollection({
  name: 'Entreprises',
  path: 'enterprises',
  schema: enterpriseSchema,
  group: 'Données',
  permissions: ({ user, authController }) => ({
    edit: true,
    create: true,
    delete: true
  }),
  // textSearchDelegate: enterprisesSearchDelegate,
  additionalColumns: [enterpriseAdditionalColumn],
  excludedProperties: ['modules', 'statusLabels', 'createdAt', 'updatedAt'],
  defaultSize: 'xs',
  // initialSort: ['name', 'asc'],
  subcollections: [
    {
      path: 'users',
      schema: userSchema,
      name: 'Utilisateurs',
      callbacks: {
        async onSaveSuccess(props: EntityOnSaveProps<User>) {
          const userPath = `${props.path}/${props.entityId}`
          const userPersistence = getFunctionCallable('userPersistence')
          await userPersistence({
            action: 'postProcess',
            userPath: userPath
          })
          console.log(`callback: user post-process executed for ${userPath}`)

          const accountPersistence = getFunctionCallable('accountPersistence')
          await accountPersistence({
            action: 'resetOne',
            userPath: userPath
          })
          console.log(`callback: account reset executed for ${userPath}`)
        }
      }

      // filterableProperties: ['firstName', 'lastName']
    },
    {
      path: 'pastSubscriptions',
      schema: pastSubscriptionsSchema,
      name: 'Historique des abonnements'
    },
    {
      path: config.collection('pastPresences'),
      schema: pastPresencesSchema,
      name: 'Historique des présences'
    }
  ],
  callbacks: {
    async onSaveSuccess(props: EntityOnSaveProps<LightEnterprise>) {
      const enterprisePersistence = getFunctionCallable('enterprisePersistence')
      const enterprisePath = `${config.collection('enterprises')}/${props.entityId}`
      await enterprisePersistence({
        action: 'postProcess',
        enterprisePath: enterprisePath
      })
      console.log(`callback: enterprise post-process executed for ${enterprisePath}`)
    }
  }
})

export default enterpriseCollection
